
import { defineComponent, inject, ref, watch } from 'vue'
import { goShopList, goShopDelete } from '@/service/main/main'
import { ElMessageBox, ElMessage } from 'element-plus'

export default defineComponent({
  props: ['searchInput'],
  async setup(props) {
    watch(
      () => props.searchInput,
      (newValue, oldValue) => {
        console.log({ newValue, oldValue })
        searchInputProps = newValue
        goList(newValue)
      },
    )
    // 刷新页面
    const reload: any = inject('reload')

    // 接受input传输值
    let searchInputProps = ''
    let shopRes = ref()
    let totalPage = ref()
    // 分页
    const currentPage = ref(1)
    // 获取商品列表
    let shopList = ref()
    // 每页页数
    const pages = 10

    const goList = async (searchInputProps: any) => {
      // 分页
      shopRes.value = await goShopList(
        searchInputProps,
        currentPage.value - 1,
        pages,
        1,
      )
      totalPage.value = Math.ceil(shopRes.value.Total / pages) * 100
      shopList.value = shopRes.value.List
      console.log(totalPage)
    }
    goList(searchInputProps)

    // 分页事件
    const handleCurrentChange = async (val: any) => {
      currentPage.value = val
      let shopRes = await goShopList(
        searchInputProps,
        currentPage.value - 1,
        pages,
        1,
      )
      shopList.value = shopRes.List
      console.log(shopList.value)
    }
    // 删除
    const open = (goodsId: number | string) => {
      ElMessageBox.confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          goShopDelete(goodsId)
          ElMessage({
            type: 'success',
            message: '删除成功!',
          })
          reload()
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消删除',
          })
        })
    }
    const handleDelete = (row: any) => {
      console.log(row)

      open(row.GoodsId)
    }

    return {
      shopRes,
      shopList,
      currentPage,
      totalPage,
      searchInputProps,
      handleCurrentChange,
      handleDelete,
    }
  },
})
