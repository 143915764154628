
import { defineComponent, onMounted, ref } from 'vue'
import { MainHeader } from '@/components/main-header/index'
import noWarehousingList from './cpns/no-warehousing-list.vue'
import { HyTable } from '@/base-ui/table/index'
import tableItems from './config/tableItems-config'
import { goWxProducts, addShopGood } from '@/service/main/main'
import { ElMessage } from 'element-plus'
export default defineComponent({
  components: { MainHeader, noWarehousingList, HyTable },
  setup() {
    const AddShopCard = ref()
    // 获取组件ref
    const HyTable = ref()
    onMounted(() => {
      return HyTable
    })
    onMounted(() => {
      return AddShopCard
    })
    // 价格区间类型
    let PriceType = ref(1)
    // 默认页码
    const currentPage = ref(1)
    // 向上取整 计算总页数
    let totalPage = ref()
    // 获取商品列表
    let tableData = ref()
    // 每页页数
    const pages = 10
    // 导入的商品列表
    let goWxProductsRes: any = []
    async function goList() {
      goWxProductsRes = await goWxProducts(currentPage.value, pages)
      totalPage.value = Math.ceil(goWxProductsRes.Total / pages) * 100
      tableData.value = goWxProductsRes.List
    }

    // 分页事件
    const handleCurrentChange = async (val: any) => {
      console.log(val)
      currentPage.value = val
      goWxProductsRes = await goWxProducts(currentPage.value, pages)
      tableData.value = goWxProductsRes.List
    }
    // 显示table组件
    goList()
    const addShopShow = () => {
      HyTable.value.changeVisibility(true)
    }
    // 确认添加
    async function myAddShop(GoodsIds: number[]) {
      if (GoodsIds.length == 0) {
        ElMessage({
          type: 'error',
          message: '请选择商品!',
        })
      }
      const ProductRes = await addShopGood(GoodsIds, PriceType.value)

      if (ProductRes[0].code == 0) {
        ElMessage({
          type: 'success',
          message: '添加成功!',
        })
        HyTable.value.changeVisibility(false)
      }
      HyTable.value.changeVisibility(false)
    }
    let searchInput = ref()
    const inputChange = (e: any) => {
      // console.log(e)
      searchInput.value = e
    }

    return {
      myAddShop,
      handleCurrentChange,
      goList,
      addShopShow,
      AddShopCard,
      tableItems,
      tableData,
      HyTable,
      searchInput,
      PriceType,
      totalPage,
      inputChange,
    }
  },
})
