import { ITableItem } from '@/base-ui/table/index'
const tableItems: ITableItem[] = [
  // {
  //   label: '图片',
  //   width: 130,
  //   prop: 'ImgUrl',
  // },
  {
    label: '商品名称',
    width: 140,
    prop: 'Name',
  },
  {
    label: '价格',
    width: 140,
    prop: 'Price',
  },
]

export default tableItems
